<template>
  <div class="c-max-md-display-none sticky-bottom text-white d-flex flex-column justify-content-center"
       style=" z-index: 1019; border-radius: 32px; width: calc(100vw - 32px); bottom:16px; left: 16px;">


    <div class="d-flex flex-row justify-content-between" style="height: 56px;">
      <button
          class="btn me-3 d-flex flex-row justify-content-between p-0 card-color text-white align-items-center border-0"
          style="height: 56px; border-radius: 56px;"
          v-if="enableReturnButton"
          @click="goToMenu">

        <div class="d-flex flex-column justify-content-center" style="height: 56px; width: 56px;">
          <font-awesome-icon :icon="['fa', 'arrow-left']"
                             class="d-flex flex-row align-self-center text-white"
                             style="height: 24px; width: 24px"/>
        </div>
        <!--      <span class="me-4">Volver</span>-->
      </button>

      <button
          class="btn d-flex flex-row justify-content-center p-0 card-color text-white border-0 align-items-center w-100"
          style="height: 56px; border-radius: 56px; min-width: 271px;"
          :style="disableFunction?'opacity:1; background-color:gray!important':''"
          :disabled="disableFunction"
          @click="mainFunction">

        <!--      <span class="ms-4 fw-semibold">{{ total_items }} {{ total_items === 1 ? 'artículo' : 'artículos' }}</span>-->

        <span class="fw-500">{{ content }}</span>

        <!--        <div class="d-flex flex-column justify-content-center " style="height: 56px; width: 56px;">
                  <font-awesome-icon :icon="['fa', 'cart-shopping']"
                                     class="d-flex flex-row align-self-center text-white"
                                     style="height: 24px; width: 24px"/>
                </div>-->

      </button>

    </div>


    <!--    <button class="btn m-0 p-0 d-flex flex-column justify-content-center card-color border-0 w-100"
                style="height: 56px; border-radius: 32px;"
                data-bs-toggle="modal"
                data-bs-target="#cartModal"
                v-if="total_items===0">

          <div class="d-flex flex-row justify-content-center w-100" style="height: 56px;">
            <font-awesome-icon :icon="['fa', 'cart-shopping']"
                               class="d-flex flex-row align-self-center text-white"
                               style="height: 24px; width: 24px"/>
          </div>

        </button>-->
  </div>
</template>

<script>
import {computed, defineAsyncComponent} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "ActionBar",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    RectangularButton: defineAsyncComponent(() => import("@/modules/components/RectangularButton")),
    // eslint-disable-next-line vue/no-unused-components
    IconText: defineAsyncComponent(() => import("@/modules/components/IconText")),
    // eslint-disable-next-line vue/no-unused-components
    TypedModal: defineAsyncComponent(() => import("@/modules/components/TypedModal")),
    // eslint-disable-next-line vue/no-unused-components
    IconButton: defineAsyncComponent(() => import("@/modules/components/IconButton")),
  },
  props: {
    enableReturnButton: {
      type: Boolean,
      default: true
    },
    disableFunction: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      required: true
    },
    mainFunction: {
      type: Function,
      required: false
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const cart = computed(() => store.getters['cartModule/getItems']())

    return {
      cart,
      router,
      total_items: computed(() => {
        if (cart.value.length === 0) return 0
        else {
          return cart.value.map((a) => a.quantity).reduce((prev, next) => prev + next)
        }
      }),
      goToCart: () => router.push({
        name: 'cart',
        query: {table_id: router.currentRoute.value.query.table_id}
      }),
      goToMenu: () => router.push({
        name: 'item',
        query: {table_id: router.currentRoute.value.query.table_id}
      })
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .c-max-md-display-none {
    display: inline !important;
  }
}

@media (min-width: 768px) {
  .c-max-md-display-none {
    display: none !important;
  }
}

.card-color {
  background-color: black !important;
}

.card-color:hover {
  background-color: rgb(51, 51, 51) !important;
}
</style>
