export const setLog = (state, log) => {
    state.log = log
}

export const setRestaurant = (state, restaurant) => {
    state.restaurant = restaurant
}

export const setMorning = (state, morning) => {
    state.morning = morning
}

export const setAfternoon = (state, afternoon) => {
    state.afternoon = afternoon
}
