import {api} from "@/main";
import axios from "axios";

// eslint-disable-next-line no-unused-vars
export const sendTable = async ({commit}, table) => {
    const url = api + '/t'
    const params = {
        'table': table
    }
    const config = {headers: {'content-type': 'application/json'}}
    try {
        const res = await axios.post(url, params, config)
        return {ok: true, data: res.data}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}
