export const getLog = (state) => () => {
    return state.log
}

export const getRestaurant = (state) => () => {
    return state.restaurant
}



export const getMorning = (state) => () => {
    return state.morning
}


export const getAfternoon = (state) => () => {
    return state.afternoon
}
