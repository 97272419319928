export const adminRouter = {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/modules/admin/pages/AdminPage'),

    children: [
        {
            path: '',
            name: 'no-item',
            component: () => import(/* webpackChunkName: "noItemPage" */ "@/modules/admin/pages/NoItemPage"),
        },
        {
            path: ':id',
            name: 'item-content',
            component: () => import("@/modules/admin/pages/ItemPage"),
            props: (route) => {
                const id = route.params.id
                return isNaN(id) ? {item_id: null} : {item_id: Number(id)}
            },
        }
    ]
}
