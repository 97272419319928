export const authRouter = {
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/pages/AuthPage'),
    children: [
        {
            path: '',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '@/modules/auth/components/Login')
        },
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ '@/modules/auth/components/Register')
        },
    ]
}
