export const setItem = (state, item) => {
    state.item = item
}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const addItem = (state, item) => {

    const array = state.items.map(i => i.item_id)
    const element = item.item_id
    let idx = array.indexOf(element)

    const indices = []
    while (idx !== -1) {
        indices.push(idx)
        idx = array.indexOf(element, idx + 1)
    }

    if (indices.length === 0) {

        const aux = {...item}
        aux.quantity = 1
        state.items = [...state.items, aux]

    } else {
        //console.log(item.customisations.map(cus => cus.options[0]).flat().map(e => e.option_id + '_' + e.quantity))
        let myItemArray = item.customisations.map(cus => cus.options).flat().map(e => e.option_id + '_' + e.quantity)
        //item.customisations.map(cus => cus.options).map(it => it[0]['name'].option_id)

        let i = 0
        let bool = true
        while (i < indices.length && bool) {
            //console.log(state.items[indices[i]].customisations.map(cus => cus.options[0]).flat().map(e => e.option_id + '_' + e.quantity))
            let checkItemArray = state.items[indices[i]].customisations.map(cus => cus.options).flat().map(e => e.option_id + '_' + e.quantity)
            //state.items[indices[i]].customisations.map(cus => cus.options).map(it => it[0]['name'].option_id)

            if (arraysEqual(myItemArray, checkItemArray)) {
                let pos = indices[i]
                const aux = {...state.items[pos]}
                aux.quantity += 1
                state.items[pos] = aux
                bool = false
            }

            i++
        }
        if (bool) {
            const aux = {...item}
            aux.quantity = 1
            state.items = [...state.items, aux]
        }

    }

}

export const addItemByIndex = (state, index) => {
    state.items[index].quantity += 1
}

export const removeItemByIndex = (state, index) => {
    if (state.items[index].quantity > 1) state.items[index].quantity -= 1
    else state.items.splice(index, 1)
}

/*
export const removeItem = (state, item) => {
    const idx = state.items.map(i => i.item_id).indexOf(item.item_id)

    if (state.items[idx].quantity > 1) {
        state.items[idx].quantity -= 1
    } else {
        state.items = state.items.filter(i => i.name !== item.name)
    }

}
*/


export const clearItems = (state) => {
    state.items = []

}

