<template>

  <div class="position-relative" style="min-width: 375px!important; padding:0 !important;">
    <Navbar/>

    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>

    <Fab/>
  </div>

</template>

<script>
import Navbar from "@/modules/app/components/Navbar";
import Fab from "@/modules/app/components/Fab"
import AppFooter from "@/modules/app/components/AppFooter";
import ActionBar from "@/modules/app/components/ActionBar";

export default {
  components: {
    ActionBar,
    AppFooter,
    Navbar,
    Fab
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "uber-move-text", sans-serif;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextRegular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "uber-move-text";
  src: url('../src/assets/fonts/uber-move-text/UberMoveTextBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}


@media (min-width: 576px) {
  .sm-display-none {
    display: none;
  }

  .br-3 {
    bottom: 3rem;
    right: 3rem
  }
}

</style>

