export const getItems = (state) => () => {
    return state.items
}


export const getCategories = (state) => () => {
    return state.categories
}


export const getRestaurant = (state) => () => {
    return state.restaurant
}

export const getIsLoading = (state) => () => {
    return state.isLoading
}


export const getRecommends = (state) => () => {
    return state.recommends
}



export const getActiveId = (state) => () => {
    return state.activeId
}

export const getItemById = (state) => (iid) => {
    const id = state.items.map(i => i.item_id).indexOf(iid)
    return {...state.items[id]}
}
