import {createStore} from 'vuex'

import itemModule from "@/modules/item/store";
import adminModule from "@/modules/admin/store";
import appModule from "@/modules/app/store";
import cartModule from "@/modules/cart/store";
import orderModule from "@/modules/order/store";

import createPersistedState from "vuex-persistedstate";
import tableModule from "@/modules/table/store";

const store = createStore({
    modules: {
        itemModule,
        adminModule,
        appModule,
        cartModule,
        orderModule,
        tableModule
    },

    plugins: [createPersistedState()]

})

export default store
