export const tableRouter = {
    name: 'table-main',
    component: () => import(/* webpackChunkName: "item" */ '@/modules/table/pages/MainPage'),
    children: [
        {
            path: '',
            name: 'table',
            component: () => import(/* webpackChunkName: "home" */ '@/modules/table/pages/TablePage')
        },
        {
            path: ':id',
            name: 'table-detail',
            component: () => import(/* webpackChunkName: "itemPage" */'@/modules/table/pages/TableDetailPage'),
            props: (route) => {
                const id = Number(route.params.id)
                return isNaN(id) ? {table_id: 0} : {table_id: id}
            },
        }
    ],
}

