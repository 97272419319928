export const addObject = (state, object) => {
    state.cart = object.cart
    state.telephone = object.telephone
    state.direction = object.direction
    state.direction2 = object.direction2
    state.isCash = object.isCash
    // state.cash = object.cash
    state.schedule = object.schedule
    state.isCollect = object.isCollect
    state.city = object.city
    state.gift = object.gift
    state.note = object.note
}
