import {api} from "@/main";
import axios from "axios";

export const postLog = async ({commit}) => {
    const url = api + '/log' + '/0'
    const res = await axios.post(url)
    commit('setLog', res.data.log)
    return res.data.log
}


export const loadLog = async ({commit}) => {
    const url = api + '/log'

    try {
        const res = await axios.get(url)
        const log = res.data.log
        commit('setLog', log)
    } catch (e) {
        commit('setLog', null)
    }
}

export const loadRestaurant = async ({commit}) => {
    const url = api + '/restaurant' + '/0'

    try {
        const res = await axios.get(url)
        const restaurant = res.data.restaurant
        commit('setRestaurant', restaurant)
        commit('setMorning', restaurant.morning.map(e => e.value))
        commit('setAfternoon', restaurant.afternoon.map(e => e.value))
        return restaurant
    } catch (e) {
        commit('setRestaurant', null)
    }
}
