<template>

  <!--  <nav id="main-navbar" class="sticky-top navbar navbar-expand-sm navbar-light bg-gray">

      <div class="container-fluid custom-nav-px" id="navbar"
           style="margin: 0 auto; max-width: 1920px;">-->

  <div class="position-fixed max-md-display-none" style="bottom: 1rem; right: 1rem; z-index: 1020"
       v-if="router.currentRoute.value.name!=='cart'">

    <button class="btn d-flex flex-row justify-content-between p-0 card-color text-white border-0 align-items-center"
            style="height: 56px; border-radius: 56px;"
            v-if="total_items!==0"
            @click="goToCart">

      <span class="ms-4 fw-500">{{ total_items }} {{ total_items === 1 ? 'artículo' : 'artículos' }} </span>

      <div class="d-flex flex-column justify-content-center " style="height: 56px; width: 56px;">
        <font-awesome-icon :icon="['fa', 'cart-shopping']"
                           class="d-flex flex-row align-self-center text-white"
                           style="height: 24px; width: 24px"/>
      </div>

    </button>

    <button class="btn m-0 p-0 d-flex flex-column justify-content-center card-color border-0"
            style="height: 56px; width: 56px; border-radius: 32px;"
            data-bs-toggle="modal"
            data-bs-target="#cartModal"
            v-else>

      <div class="d-flex flex-row justify-content-center w-100" style="height: 56px; width: 56px;">
        <font-awesome-icon :icon="['fa', 'cart-shopping']"
                           class="d-flex flex-row align-self-center text-white"
                           style="height: 24px; width: 24px"/>
      </div>

    </button>
  </div>

  <div class="position-fixed max-md-display-none" style="bottom: 1rem; right: 1rem; z-index: 1020"
       v-else>
    <button class="btn d-flex flex-row justify-content-between p-0 card-color text-white align-items-center border-0"
            style="height: 56px; border-radius: 56px;"
            @click="goToMenu">

      <div class="d-flex flex-column justify-content-center" style="height: 56px; width: 56px;">
        <font-awesome-icon :icon="['fa', 'arrow-left']"
                           class="d-flex flex-row align-self-center text-white"
                           style="height: 24px; width: 24px"/>
      </div>
      <span class="me-4 fw-500">Volver</span>
    </button>
    <!--      <IconButton class="flex-wrap-reverse"
                      content="Volver"
                      style="height: 64px;"
                      :icon="['fa', 'arrow-left']"/>-->

  </div>

  <TypedModal name="cart">
    <template #header>
      <IconText content="Cesta"
                class="fs-4 fw-700"
                :icon="['fa', 'fa-shopping-cart']"/>
    </template>

    <template #body>
      <div class="mb-3">
        Añade artículos para iniciar un nuevo pedido
      </div>
      <div class="d-flex justify-content-center w-100">
        <IconButton content="Añadir artículos"
                    :icon="['fa','fa-plus']"
                    class="bg-editable fs-14px"
                    data-bs-dismiss="modal"/>
      </div>
    </template>

  </TypedModal>

</template>

<script>
import {computed, defineAsyncComponent} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "FaButton",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    RectangularButton: defineAsyncComponent(() => import("@/modules/components/RectangularButton")),
    // eslint-disable-next-line vue/no-unused-components
    IconText: defineAsyncComponent(() => import("@/modules/components/IconText")),
    // eslint-disable-next-line vue/no-unused-components
    TypedModal: defineAsyncComponent(() => import("@/modules/components/TypedModal")),
    // eslint-disable-next-line vue/no-unused-components
    IconButton: defineAsyncComponent(() => import("@/modules/components/IconButton")),
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const cart = computed(() => store.getters['cartModule/getItems']())

    return {
      cart,
      router,
      total_items: computed(() => {
        if (cart.value.length === 0) return 0
        else {
          return cart.value.map((a) => a.quantity).reduce((prev, next) => prev + next)
        }
      }),
      goToCart: () => router.push({
        name: 'cart',
        query: {table_id: router.currentRoute.value.query.table_id}
      }),
      goToMenu: () => router.push({
        name: 'item',
        query: {table_id: router.currentRoute.value.query.table_id}
      })
    }
  }
}
</script>

<style scoped>

.card-color {
  background-color: black !important;
}

.card-color:hover {
  background-color: rgb(51, 51, 51) !important;
}

@media (max-width: 767px) {
  .max-md-display-none {
    display: none;
  }
}

</style>
