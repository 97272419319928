
export const setItems = (state, items) => {
    state.items = items
    state.isLoading = false
}

export const setCategories = (state, categories) => {
    state.categories = categories
    //state.isLoading = false
}

export const setActiveId = (state, activeId) => {
    state.activeId = activeId
}

export const setRecommends = (state, recommends) => {
    state.recommends = recommends
}

export const setItem = (state, item) => {
    state.item = item
}
export const setRestaurant = (state, restaurant) => {
    state.restaurant = restaurant
}
