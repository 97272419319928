export default () => ({
    isLoading: true,
    cart: [],
    telephone: 0,
    direction: "",
    direction2: "",
    isCash: false,
    // cash: 0,
    schedule: "",
    isCollect: false,
    city: "",
    gift: "",
    note: "",
    table:""
})
