import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/styles.scss'
import bootstrap from 'bootstrap'
import "vue-progressive-image/dist/style.css"
import VueProgressiveImage from 'vue-progressive-image'

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import fonts awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faArrowRight, faArrowLeft, faEllipsis, faTrash, faCartShopping, faLocationDot, faReceipt, faPhone,
    faClock, faCalendarDay, faCheck, faPlus, faPen, faPenToSquare, faNoteSticky, faLeaf, faCocktail,
    faCreditCard, faMoneyBill, faMotorcycle, faPersonWalking, faGift, faMapLocationDot
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
    faArrowRight, faArrowLeft, faEllipsis, faTrash, faCartShopping, faLocationDot, faReceipt, faPhone,
    faClock, faCalendarDay, faCheck, faPlus, faPen, faPenToSquare, faNoteSticky, faLeaf, faCocktail,
    faCreditCard, faMoneyBill, faMotorcycle, faPersonWalking, faGift, faMapLocationDot
)

const development = false

const api = development ? 'http://127.0.0.1:5000' : 'https://lagranmurallaoriental.herokuapp.com'
export {api}

const app = createApp(App)
const cors = require('cors')

app.use(store)
    .use(router)
    .use(cors)
    .use(VueProgressiveImage)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

const toDecimal = (num) => parseFloat(num).toFixed(2)
const toDecimalPrice = (num) => parseFloat(num).toFixed(2) + ' €'

export {
    toDecimal, toDecimalPrice
}
