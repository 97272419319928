/* export const myAction = async ({commit})=>{}*/

import {api} from "@/main";
import axios from "axios";

/*DONE*/
export const loadCategories = async ({commit}) => {
    const url = api + '/categories'
    // const token = localStorage.getItem('token')
    // const config = {auth: {username: token, password: ''}}
    try {
        const res = await axios.get(url)
        commit('setCategories', res.data.categories)
    } catch (e) {
        commit('setCategories', [])
    }
}

/*DONE*/
export const loadItems = async ({commit}) => {
    const url = api + '/items'
    // const token = localStorage.getItem('token')
    // const config = {auth: {username: token, password: ''}}
    try {
        const res = await axios.get(url)
        console.log('asd')
        commit('setItems', res.data.items)
    } catch (e) {
        commit('setItems', [])
    }
}

/*DONE - ON DEMAND*/
export const createUser = async ({commit}, {user}) => {
    const url = api + '/user/' + user.user_id
    const token = localStorage.getItem('token')
    const config = {auth: {username: token, password: ''}}
    try {
        const res = await axios.post(url, {...user}, config)
        commit('addUser', res.data.user)
        return {ok: true, user_id: res.data.user.user_id}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}

/*DONE*/
export const updateUser = async ({commit}, {user}) => {
    const id = user.user_id
    const params = {...user}
    delete params.user_id
    const url = api + '/user/' + id
    const token = localStorage.getItem('token')
    const config = {auth: {username: token, password: ''}}
    try {
        const res = await axios.put(url, params, config)
        commit('updateUser', res.data.user)
        return {ok: true, data: res.data.user}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}

/*DONE*/
export const deleteUser = async ({commit}, {user_id}) => {
    const url = api + '/user/' + user_id
    const token = localStorage.getItem('token')
    const config = {auth: {username: token, password: ''}}
    try {
        const res = await axios.delete(url, config)
        commit('deleteUser', user_id)
        return {ok: true, data: res.data.user}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}

/*DONE*/
export const deleteAccount = async ({commit}, {email}) => {
    const url = api + '/account'
    const token = localStorage.getItem('token')
    const params = {email}
    const config = {auth: {username: token, password: ''}, params}
    try {
        const res = await axios.delete(url, config)
        return {ok: true, data: res.data.account}
    } catch (e) {
        return {ok: false, data: e.response.data}
    }
}

