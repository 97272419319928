export const getCart = (state) => () => {
    return state.cart
}

export const getTelephone = (state) => () => {
    return state.telephone
}

export const getCash = (state) => () => {
    return state.cash
}

export const getDirection = (state) => () => {
    return state.direction
}

export const getDirection2 = (state) => () => {
    return state.direction2
}


export const getIsCash = (state) => () => {
    return state.isCash
}

export const getGift = (state) => () => {
    return state.gift
}

export const getIsCollect = (state) => () => {
    return state.isCollect
}

export const getCity = (state) => () => {
    return state.city
}

export const getNote = (state) => () => {
    return state.note
}

export const getSchedule = (state) => () => {
    return state.schedule
}

