<template>
  <div class="" style=" border-top: 1px solid #E2E2E2; margin: 10rem 0 1rem" v-if="router.currentRoute.value.name!=='item-detail'">

    <div class="row custom-nav-px" style="margin: 0 auto; max-width: 1440px">

      <div class="px-0 d-flex fd-footer gap-40px">

        <div class="w-100">
          <div class="">
            <div class="fw-bold text-start">
              La Gran Muralla Oriental
            </div>
            <hr>
            <div class="fw-light text-start">
              La Gran Muralla Oriental especializado en comida china, te invita a probar sus deliciosos platos asiáticos
              cómodamente. Descubre la gran variedad de platos realizados sólo
              con los mejores ingredientes. Echa un vistazo a su extensa carta, ¡No te dejará indiferente! Haz tu pedido
              ahora y ¡a disfrutar!. ¿A qué esperas?
            </div>

            <!--          <div class="fw-bold text-start mt-3">
                        Follow us
                      </div>
                      <ul class="list-group list-group-horizontal">
                        <li class="list-group-item w-25 bg-transparent border-0">
                          <a href=""><i class="fab fa-twitter blue"></i></a>
                        </li>
                        <li class="list-group-item w-25 bg-transparent border-0">
                          <a href=""><i class="fab fa-instagram blue"></i></a>
                        </li>
                        <li class="list-group-item w-25 bg-transparent border-0">
                          <a href=""><i class="fab fa-facebook blue"></i></a>
                        </li>
                        <li class="list-group-item w-25 bg-transparent border-0">
                          <a href=""><i class="fab fa-linkedin blue"></i></a>
                        </li>
                      </ul>-->
          </div>
        </div>

        <div class="w-100">
          <div class="fw-bold text-start">
            Directorios
          </div>
          <hr>
          <ul class="list-group">
            <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
              <a class="text-decoration-none text-black" @click="goToHome">
                Inicio</a>
            </li>
            <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
              <a class="text-decoration-none text-black" @click="goToCarta">
                La carta</a>
            </li>
            <!--          <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
                        <a class="text-decoration-none" @click="goToMenu" style="color: black">
                          Los menús</a>
                      </li>-->
          </ul>
        </div>

        <!--      <div class="col-5  mt-4">
                <div class="fw-bold text-start">
                  Contact
                </div>
                <hr>
                <ul class="list-group">
                  <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
                    <a class="text-decoration-none" href="tel://695 47 64 46">
                      <i class="fa fa-phone " style="width: 1.5rem"></i>
                      695 47 64 46
                    </a>
                  </li>
                  <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
                    <a class="text-decoration-none" href="mailto:info@yoursite.com">
                      <i class="fa fa-envelope " style="width: 1.5rem"></i>
                      qijunjin1996@hotmail.com
                    </a>
                  </li>
                  <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
                    <a class="text-decoration-none" href="https://goo.gl/maps/vUPHVgJQm8VGKZpZ8">
                      <i class="fa fa-map-marker " style="width: 1.5rem"></i>
                      University of Barcelona
                    </a>
                  </li>
                  <li class="list-group-item bg-transparent border-0 text-start pt-0 pl-0">
                    <a class="text-decoration-none" href="">
                      <i class="fa fa-globe " style="width: 1.5rem"></i>
                      peerevaluationsystem.herokuapp.com
                    </a>
                  </li>
                </ul>
              </div>-->
      </div>
    </div>

    <div class="row mx-0 p-0" style="margin-top: 100px">
      <div class=" text-center">
        <div>
          Copyright &copy;{{ new Date().getFullYear() }}
          All rights reserved | Designed and developed by QJStudio
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "AppFooter",
  setup() {
    const router = useRouter()

    return {
      router,
      goToHome: () => router.push({name: 'home'}),
      goToCarta: () => router.push({name: 'item'}),
      goToMenu: () => router.push({name: 'menu-home'}),
    }
  }
}
</script>
<style>
@media (max-width: 768px) {
  .md-px-3 {
    margin: 0 1rem;
  }
}

@media (min-width: 767px) {
  .fd-footer {
    flex-direction: row !important;
  }
}

@media (max-width: 766px) {
  .fd-footer {
    flex-direction: column !important;
  }
}

@media (max-width: 992px) {
  .lg-none {
    display: none;
  }

  .custom-nav-px {
    padding: 100px 1rem 0 !important;
    margin-top: 200px;
  }
}

@media (min-width: 991px) {
  .custom-nav-px {
    padding: 100px 3rem 0 !important;
    margin-top: 200px;
  }
}

.gap-40px {
  gap: 40px !important;
}
</style>
