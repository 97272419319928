export const appRouter = {
    name: 'app',
    component: () => import(/* webpackChunkName: "mainApp" */ '@/modules/app/pages/AppPage'),
    children: [
        {
            path: '',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '@/modules/item/pages/ItemPage'),
            props: (route) => {
                const table_id = Number(route.query.table_id)
                return isNaN(table_id) ? {table_id: 0} : {table_id: table_id}
            },
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ '@/modules/app/pages/AboutPage')
        },

    ]
}
