export default () => ({
    isLoading: true,
    items: [],
    a: [
        {
            "item_id": 204,
            "name": "Menú para 4 personas",
            "url": "https://res.cloudinary.com/doxnnrm8o/image/upload/v1690925452/lunch_xcajbq.png",
            "description": null,
            "description_2": null,
            "category_id": 3,
            "order_id": 4,
            "price": 39.5,
            "available": true,
            "quantity": 1,
            "customisations": [{
                "customisation_id": 19,
                "name": "Añadir entrante",
                "quantity": 2,
                "options": [{
                    "option_id": 1,
                    "name": "Ensalada china",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }, {
                    "option_id": 17,
                    "name": "Pan de gambas",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 1,
                    "available": true,
                    "quantity": 1
                }, {
                    "option_id": 18,
                    "name": "Wantun frito",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 2,
                    "available": true,
                    "quantity": 0
                }]
            }, {
                "customisation_id": 20,
                "name": "Añadir entrante",
                "quantity": 4,
                "options": [{
                    "option_id": 3,
                    "name": "Rollo de primavera",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 4
                }]
            }, {
                "customisation_id": 9,
                "name": "Añadir principal",
                "quantity": 1,
                "options": [{
                    "option_id": 20,
                    "name": "Arroz frito con jamón dulce",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 21,
                "name": "Añadir principal",
                "quantity": 1,
                "options": [{
                    "option_id": 26,
                    "name": "Tallarines tres delicias",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 10,
                "name": "Añadir proteina",
                "quantity": 1,
                "options": [{
                    "option_id": 50,
                    "name": "Pollo con almendras",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 12,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 11,
                "name": "Añadir principal o proteina",
                "quantity": 1,
                "options": [{
                    "option_id": 26,
                    "name": "Tallarines tres delicias",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 18,
                "name": "Añadir proteina",
                "quantity": 1,
                "options": [{
                    "option_id": 19,
                    "name": "Bolas de pollo frito",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 22,
                "name": "Añadir proteina",
                "quantity": 1,
                "options": [{
                    "option_id": 75,
                    "name": "Cerdo agridulce",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 17,
                    "available": true,
                    "quantity": 1
                }]
            }, {
                "customisation_id": 61,
                "name": "Elegir una salsa",
                "quantity": 1,
                "options": [{
                    "option_id": 562,
                    "name": "Sin salsa",
                    "price": 0,
                    "ref_id": "",
                    "order_id": 0,
                    "available": true,
                    "quantity": 1
                }]
            }]
        }]
})
