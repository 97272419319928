import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import {appRouter} from "@/modules/app/router";
import {itemRouter} from "@/modules/item/router";
import {authRouter} from "@/modules/auth/router";
import {adminRouter} from "@/modules/admin/router";
import {cartRouter} from "@/modules/cart/router";
import {orderRouter} from "@/modules/order/router";
import {tableRouter} from "@/modules/table/router";

const routes = [
    {
        path: '',
        ...appRouter
    },
    {
        path: '/auth',
        ...authRouter
    },
    {
        path: '/item',
        ...itemRouter
    },
    {
        path: '/cart',
        ...cartRouter
    },
    {
        path: '/admin',
        ...adminRouter
    },
    {
        path: '/table',
        ...tableRouter
    },
    {
        path: '/order',
        ...orderRouter
    },
    {
        path: '/:catchAll(.*)*',
        name: "notfoundpage",
        component: () => import(/* webpackChunkName: "notfound" */ '@/modules/item/pages/ItemPage')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
})

export default router
